import AppRoutes from '@/routes';
import { NotificationProvider, ThemeProvider } from '@/common/context';
import './assets/scss/Saas.scss';

const App = () => {
    return (
        <ThemeProvider>
            <NotificationProvider>
                <AppRoutes />
            </NotificationProvider>
        </ThemeProvider>
    );
};

export default App;
