import { Card } from 'react-bootstrap';
import './ConversationAdditiionalInfo.css';
import { useAppContext } from '@/context/App.context';
import { useQuery } from '@tanstack/react-query';
import { ClientFactory } from '@/client/ClientFactory';
import { useCallback, useState } from 'react';
import { VisitTag } from '@/model/Customer';

export function AdditionalInfo({ customerId }: { customerId: string }): JSX.Element {
    const { serviceIds } = useAppContext();
    // const targetTimezone = 'America/New_York'; // Timezone example

    const { data: customerInfo } = useQuery({
        queryKey: ['customer', customerId],
        queryFn: async () => ClientFactory.createGaramServiceClient().getCustomerInfo(serviceIds.serviceId, customerId),
    });

    const [showVisitPage, setShowVisitPage] = useState<boolean>(false);

    const onClickVisitPage = useCallback(() => {
        setShowVisitPage((prev) => !prev);
    }, []);

    return (
        <Card style={{ height: '100%', padding: '5px 10px', backgroundColor: '#F9F9F9' }}>
            <Card.Body style={{ display: 'flex', gap: 8, flexDirection: 'column', padding: '0px 0px' }}>
                <div className="sectionBox">
                    <p className="sectionTitle">
                        <i className="ri-customer-service-2-fill font-16"></i>
                        {` Customer Information`}
                    </p>

                    <p className="sectionContent">
                        <i className="ri-map-pin-5-fill font-16"></i>
                        {` CountryCode ${customerInfo?.geoLocation.countryCode}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-map-pin-5-fill font-16"></i>
                        {` Region ${customerInfo?.geoLocation.region}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-building-4-fill font-16"></i>
                        {` City ${customerInfo?.geoLocation.city}`}
                    </p>
                    <p className="sectionContent">
                        <i className="ri-map-pin-time-fill font-16"></i>
                        {` Time difference ${customerInfo?.geoLocation.timeZone} hours`}
                    </p>

                    <p className="sectionContent" onClick={onClickVisitPage}>
                        <i className=" ri-chrome-fill font-16"></i>
                        {` Visit History View`}
                        {showVisitPage ? <i className="ri-arrow-up-s-line font-16"></i> : <i className="ri-arrow-down-s-line font-16"></i>}
                        {showVisitPage && (
                            <div style={{ height: 200, overflowY: 'scroll', padding: '5px 10px' }}>
                                {customerInfo?.events.map((item, index) => {
                                    const data = item.data as VisitTag;
                                    if (data.tag !== 'VisitPage') {
                                        return;
                                    }
                                    return (
                                        <>
                                            <a key={index} href={data.payload.data}>
                                                {data.payload.data}
                                            </a>
                                            <br />
                                        </>
                                    );
                                })}
                            </div>
                        )}
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
}
